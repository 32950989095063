<template>
  <div class="container-xl">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">
          <div class="flex flex-row mb-2 pr-2 float-right">
            <!-- <el-input clearable @clear="searchHandlerBanner" v-model="search" style="width: 200px" size="small" type="text" placeholder="Input search keyword" class="mr-2"/> -->
            <!-- <el-button type="primary" @click="searchHandlerBanner" size="small"><font-awesome-icon icon="search"/> Search</el-button> -->
          </div>
        </div>
        <el-button @click="handlerBtnNewEvent" type="primary" size="mini"><font-awesome-icon icon="plus"/> Create New Event</el-button>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%">Name</th>
              <th width="20%">Type</th>
              <th>User Type</th>
              <th>Max Claim </th>
              <th>Created At </th>
              <th>Updated At </th>
              <th>Status </th>
              <th>#</th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in list" :key="item.id">
              <td> {{item.name}} </td>
              <td> {{item.type_str}} </td>
              <td> {{item.user_type_str}} </td>
              <td> {{item.max_claim_days}} </td>
              <td> {{item.created_at_str}} </td>
              <td> {{item.updated_at_str}} </td>
              <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.event_status_color">{{ item.event_status_str }}</span></td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer"  @click="handlerBtnUpdateEvent(item)">
                      <font-awesome-icon class="text-primary"  :icon="['fas', 'pen']"/>&nbsp; Update Event
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnUpdateStatus(item)">
                      <font-awesome-icon class="text-primary" :icon="['fas', 'pen']"/>&nbsp; Update Status
                    </a>
                    <a class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnDelete(item)">
                      <font-awesome-icon class="text-primary" :icon="['fas', 'pen']"/>&nbsp; Delete Event
                    </a>
                  </div>
              </td>
             </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_row == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_row }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_row"
            v-model="page"
          />
        </div>
      </div>
      <b-modal size="lg" v-model="isShownModalCreateEvent" title="Create Event" hide-footer @hide="onHide">
        <b-form @submit.prevent="onSubmit" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Name">
            <b-input v-model="form.name" placeholder="Input Event Name" type="text" />
          </b-form-group>
          <b-form-group label="Title">
            <b-input v-model="form.title" placeholder="Input title" type="text" />
          </b-form-group>
          <b-form-group label="Description">
            <quill-editor v-model="form.description" ref="description" :options="editorOption" style="height: 150px"></quill-editor>
          </b-form-group><br><br><br>
          <b-form-group label="Type">
            <el-select  class="mb-2" @change="adjustRewardsConfig" v-model="form.type" placeholder="Select Type" style="width: 100%">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="User Type">
            <el-select class="mb-2" @change="handleCompanyType" v-model="form.user_type" placeholder="Select User Type" style="width: 100%">
              <el-option v-for="item in userTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Excluded Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies_excluded" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Start Date">
            <el-date-picker :picker-options="datePickerStartOptions" class="w-full" v-model="form.start_date" type="date" placeholder="Pick start date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"  @input="updateEndDatePickerOptions" />
          </b-form-group>
          <b-form-group label="End Date">
            <el-date-picker :picker-options="datePickerEndOptions" :key="endDateKey" class="w-full" v-model="form.end_date" type="date" placeholder="Pick end date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"/>
          </b-form-group>
          <b-form-group label="Max Claim Days">
            <b-input v-model="form.max_claim_days" @change="adjustRewardsConfig"  placeholder="Input max claim days" type="number"/>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.rewards_config.length > 0 && form.type === 'transaction_reward'" >
            <b-row v-for="(item, index) in form.rewards_config" :key="index" class="mb-3" align-items="center">
              <b-col cols="">
                <el-select v-model="item.product" placeholder="Select the product"  style="width: 100%" >
                  <el-option  v-for="option in availableProductOptions(index)" :key="option.value" :label="option.label"  :value="option.value" />
                </el-select>
              </b-col>
              <b-col cols="4">
                <b-input type="number" v-model="item.coins" :placeholder="item.placeholder"/>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.type === 'daily_login'">
            <b-row v-for="(item, index) in rewards_config_daily_login" :key="index" class="mb-3" align-items="center">  
              <b-col cols="">
                <b-input type="text"  :disabled="true" v-model="item.placeholder"/>
              </b-col>
              <!-- <b-col cols="">
                <b-input type="text" :disabled="true" v-model="item.day" />
              </b-col> -->
              <b-col v-if="item" cols="8">
                <b-input type="number" v-model="item.coins" :placeholder="'Input koin day ' + (index + 1)"/>
              </b-col>
            </b-row>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="onSubmit" :disabled="!isFormValid" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal size="lg" v-model="isShownModalUpdateEvent" title="Update Event" hide-footer @hide="onHide">
        <b-form @submit.prevent="doUpdateEvent" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Name">
            <b-input v-model="form.name" placeholder="Input Event Name" type="text" />
          </b-form-group>
           <b-form-group label="Title">
            <b-input v-model="form.title" placeholder="Input Event Title" type="text" />
          </b-form-group>
          <b-form-group label="Description">
            <quill-editor v-model="form.description" ref="description" :options="editorOption" style="height: 150px"></quill-editor>
          </b-form-group><br><br><br>
          <b-form-group label="Type">
            <el-select  class="mb-2"  v-model="form.type" placeholder="Select Type" style="width: 100%">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="User Type">
            <el-select  class="mb-2" @change="handleCompanyType" v-model="form.user_type" placeholder="Select User Type" style="width: 100%">
              <el-option v-for="item in userTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Exclude Company"  v-if="form.user_type === 'private'">
            <el-select multiple class="mb-2" :loading="loading.company" v-model="form.companies_excluded" @change="handleCompanyChange" placeholder="Select or search company" style="width: 100%" filterable remote :remote-method="searchCompany">
              <el-option v-for="item in companies.rows" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
            </el-select>
          </b-form-group>
          <b-form-group label="Start Date">
            <el-date-picker @input="updateEndDatePickerOptions" :picker-options="datePickerStartOptions" class="w-full" v-model="form.start_date" type="date" placeholder="Pick start date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"/>
          </b-form-group>
          <b-form-group label="End Date">
            <el-date-picker :key="endDateKey" :picker-options="datePickerEndOptions" class="w-full" v-model="form.end_date" type="date" placeholder="Pick end date" format="dd MMMM yyyy" value-format="yyyy-MM-dd"/>
          </b-form-group>
           <b-form-group label="Max Claim Days">
            <b-input v-model="form.max_claim_days"  @change="adjustRewardsConfigUpdate()" placeholder="Input max claim days" type="number"/>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.type === 'transaction_reward'">
            <b-row v-for="(item, index) in form.rewards_config" :key="index" class="mb-3" align-items="center">  
              <b-col cols="">
                <el-select v-model="item.product" placeholder="Select the product"  style="width: 100%" >
                  <el-option  v-for="option in availableProductOptions(index)" :key="option.value" :label="option.label"  :value="option.value" />
                </el-select>
              </b-col>
              <b-col v-if="item" cols="4">
                <b-input type="number" v-model="item.coins" :placeholder="'Input koin day ' + (index + 1)"/>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label="Reward Config" v-if="form.type === 'daily_login'">
            <b-row v-for="(item, index) in form.rewards_config" :key="index" class="mb-3" align-items="center">  
              <b-col cols="">
                 <b-input  type="text" :value="'Day ' + item.day"  :disabled="true" />
              </b-col>
              <b-col v-if="item" cols="8">
                <b-input type="number" v-model="item.coins" :placeholder="'Input koin day ' + (index + 1)"/>
              </b-col>
            </b-row>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="doUpdateEvent" :disabled="!isFormValid" :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal size="lg" v-model="isShownModalUpdateStatus" title="Update Status" hide-footer @hide="onHide">
        <b-form @submit.prevent="doUpdateStatus" @reset="onHide" v-loading="loading.detail">
          <b-form-group label="Status">
            <el-select  class="mb-2"  v-model="form.status" placeholder="Select Status" style="width: 100%">
              <el-option v-for="item in updateStatusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="doUpdateStatus"   :loading="loading.submit" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>
<script>
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import {
GET_BY_ID_GAMIFICATION, UPDATE_EVENT_GAMIFICATION, DELETE_EVENT_GAMIFICATION, UPDATE_STATUS_EVENT_GAMIFICATION, 
GET_GAMIFICATION_LIST, CREATE_EVENT_GAMIFICATION,  } from '@/store/modules/gamification';
import moment from 'moment';
import { GET_COMPANIES } from '@/store/modules/companies';
// import useVuelidate from '@vuelidate/core';
export default {
  components: { quillEditor },
  metaInfo: {
    title: 'Gamification',
  },
  data() {
    return {
      search_keyword: 'full_name',
      search: '',
      user_filter: {
        search_by: 'full_name',
        search_keyword: '',
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      companies: {
        rows: [],
        count: 0,
      },
      datePickerStartOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'), 
      },
      datePickerEndOptions: {
        disabledDate: (date) => moment(date).isBefore(moment(), 'days'), 
      },
      endDateKey: 0, 
      form: {
        name: '',
        title: '',
        companies: [],
        companies_excluded: [],
        description: '',
        type: '',
        user_type: '' ,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(7, 'days').format('YYYY-MM-DD'),
        status: [] ,
        max_claim_days: 0,
        rewards_config: []
      },
      rewards_config: [
        { product: 'Voucher', coins: null }, 
        { product: 'Pulsa', coins: null }, 
        { product: 'Token PLN', coins: null },
        { product: 'Paket Data', coins: null },
        { product: 'Tagihan Listrik', coins: null }, 
        { product: 'Tagihan Telepon', coins: null }, 
        { product: 'Internet & TV Kabel', coins: null }, 
        { product: 'PDAM', coins: null },
      ],
      rewards_config_daily_login : [],
      emptyStateImage,
      isShownModalUpdateEvent: false,
      isShownModalUpdateStatus: false,
      loading: {
        modal_content: false,
        table: false,
      },
      data_event_update: [],
      page: 1,
      per_page: 10,
      size: 10,
      list: [],
      total_row: 0,
      isShownModalCreateEvent: false,
      loading: {
        detail: false,
        submit: false,
        company: false,
        table: false
      },
      typeOptions: [
        {
          value: 'daily_login',
          label: 'Daily Login',
        }, 
        {
          value: 'transaction_reward',
          label: 'Transaction Reward',
        },
      ],
      editorOption: {
        placeholder: 'Event ini adalah ...',
      },
      userTypeOptions: [
        {
          value: 'private',
          label: 'Private',
        }, 
        {
          value: 'public',
          label: 'Public',
        },
         {
          value: 'all',
          label: 'All',
        },
      ],
      productTypeOptions: [
        {
          value: 'Voucher',
          label: 'Voucher',
        }, 
        {
          value: 'Pulsa',
          label: 'Pulsa',
        },
         {
          value: 'Token PLN',
          label: 'Token PLN',
        },
        {
          value: 'Paket Data',
          label: 'Token PLN',
        },
        {
          value: 'Tagihan Listrik',
          label: 'Tagihan Listrik',
        },
        {
          value: 'Tagihan Telepon',
          label: 'Tagihan Telepon',
        },
        {
          value: 'Internet & TV Kabel',
          label: 'Internet & TV Kabel',
        },
        {
          value: 'PDAM',
          label: 'PDAM',
        },
      ],
      statusOptions: [
        {
          value: 'submitted',
          label: 'Submitted',
        },
        {
          value: 'draft',
          label: 'Draft',
        },
        {
          value: 'publish',
          label: 'Publish',
        },
      ],
      updateStatusOptions: [
        {
          value: 'hold',
          label: 'Hold',
        },
        {
          value: 'draft',
          label: 'Draft',
        },
        {
          value: 'publish',
          label: 'Publish',
        },
      ],
    };
  },
  mounted() {
    this.getList();
    this.adjustRewardsConfig();
    this.adjustRewardsConfigUpdate();
  },
  computed: {
    showingFrom() {
      return this.total_row ? ((this.page - 1) * this.size) + 1 : 0;
    },
    showingUntil() {
      if (this.total_row) {
        if (this.list.length < this.size) {
          return this.total_row;
        }
        return this.page * this.size;
      }
      return 0;
    },
    showingFromApp() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntilApp() {
      if (this.total_rows) {
        if (this.list_app.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    isFormValid() {
      return this.form.name && this.form.title && this.form.description &&
             this.form.type && this.form.user_type &&
             this.form.start_date && this.form.end_date
    },
    availableProductOptions() {
      return (index) => {
        const selectedProducts = this.form.rewards_config
          .map((item, idx) => (idx !== index ? item.product : null))
          .filter((product) => product);

        return this.productTypeOptions.filter(
          (option) => !selectedProducts.includes(option.value)
        );
      };
    },
  },
  methods: {
    searchCompany(value) {
      clearTimeout(this.timeoutSearchCompany);
      this.timeoutSearchCompany = setTimeout(() => {
        this.loadCompanies({
          search_keyword: value,
        });
      }, 1000);
    },
    async loadCompanies({ search_keyword = '' } = {}) {
      await this.$store.dispatch(GET_COMPANIES, {
        search_keyword,
      });
      this.companies = this.$store.getters.companies;
    },
   async handleCompanyChange() {
      this.loadCompanies();
    },
    handleCompanyType(value) {
      if (value === 'private') {
        this.form.company_id = [];
        this.loadCompanies();
      }
    },
    updateEndDatePickerOptions() {
      if (this.form.start_date) {
        const selectedStartDate = moment(this.form.start_date, 'YYYY-MM-DD');
        this.datePickerEndOptions = {
          disabledDate: (date) => {
            const selectedDate = moment(date);
            const isDisabled = selectedDate.isBefore(moment(), 'days') || selectedDate.isBefore(selectedStartDate, 'day');
            return isDisabled;
          },
        };
        this.endDateKey += 1;
      }
    },
    adjustRewardsConfigUpdate() {
      const maxDays = parseInt(this.form.max_claim_days) || 0;
      const currentRewardsConfig = [...this.form.rewards_config]; 
      this.form.rewards_config = [];
      if (this.form.type === 'transaction_reward') {
        for (let i = 1; i <= maxDays; i++) {
          const product = currentRewardsConfig[i - 1]?.product || '';
          const coins = currentRewardsConfig[i - 1]?.coins || null;
          this.form.rewards_config.push({
            product: product,
            coins: coins,
          });
        }
      } else if (this.form.type === 'daily_login') {
        for (let i = 1; i <= maxDays; i++) {
          const coins = currentRewardsConfig[i - 1]?.coins || null;
          this.form.rewards_config.push({
            day: i, 
            coins: coins,
          });
        }
      }
    },
    adjustRewardsConfig() {
      const maxDays = parseInt(this.form.max_claim_days) || 0;
      this.form.rewards_config = [];
      this.rewards_config_daily_login = [];
      if (this.form.type === 'transaction_reward') {
        for (let i = 1; i <= maxDays; i++) {
          this.form.rewards_config.push({
            product: '',
            coins: null, 
            placeholder: `Input koin for Day ${i}`,
          });
        }
      } else if (this.form.type === 'daily_login') {
        for (let i = 1; i <= maxDays; i++) {
          this.rewards_config_daily_login.push({
            day: i,
            coins: null,
            placeholder: `Day ${i}`,
          });
        }
      }
    },
    searchHandler() {
      this.page = 1;
      this.search_by = this.user_filter.search_by;
      this.search_keyword = this.user_filter.search_keyword;
      this.getList();
    },
    handlerBtnNewEvent() {
      this.isShownModalCreateEvent = true;
    },
    async handlerBtnUpdateEvent(item) {
      this.loadCompanies();
      this.isShownModalUpdateEvent = true;
      this.selected_transaction = item;
      this.loading.table = true;
      try {
        await this.$store.dispatch(GET_BY_ID_GAMIFICATION, {
          id: this.selected_transaction._id,
        });
        const by_id_gamifications = this.$store.getters.by_id_gamifications;
        if (by_id_gamifications && by_id_gamifications.data) {
          this.form = by_id_gamifications.data || [];
        }
      } catch (err) {
        console.error("Error fetching gamification ID: ", err);
      } finally {
        this.loading.table = false;
        this.getList();
      }
    },
    async handlerBtnUpdateStatus(item) {
      this.isShownModalUpdateStatus = true;
      this.selected_transaction = item;
      this.loading.table = true;
      try {
        await this.$store.dispatch(GET_BY_ID_GAMIFICATION, {
          id: this.selected_transaction._id,
        });
        const by_id_gamifications = this.$store.getters.by_id_gamifications;
        if (by_id_gamifications && by_id_gamifications.data) {
          this.form = by_id_gamifications.data || [];
        }
      } catch (err) {
        console.error("Error fetching gamification ID: ", err);
      } finally {
        this.loading.table = false;
        this.getList();
      }
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.loading.table = true;
      try {
        await this.$store.dispatch(GET_GAMIFICATION_LIST, {
          page: this.page,
          size: this.size,
        });
        const gamifications = this.$store.getters.gamifications;
        if (gamifications && gamifications.data) {
          this.total_row = gamifications.count || 0;
          this.list = gamifications.data || [];
        }
      } catch (err) {
        console.error("Error fetching gamification list: ", err);
      } finally {
        this.loading.table = false;
      }
    },
    async onSubmit() {
      try {
        const rewardsConfigData = this.form.type === 'transaction_reward'
          ? this.form.rewards_config.map(item => ({
              product: item.product,
              coins: parseInt(item.coins) || 0,
            }))
          : this.rewards_config_daily_login.map(item => ({
              day: item.day,  
              coins: parseInt(item.coins) || 0,
            }));
        const requestBody = {
          name: this.form.name,
          title: this.form.title,
          description: this.form.description,
          companies: this.form.companies,
          companies_excluded: this.form.companies_excluded,
          type: this.form.type,
          user_type: this.form.user_type,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          max_claim_days: parseInt(this.form.max_claim_days),
          rewards_config: rewardsConfigData 
        }; 
        const response = await this.$store.dispatch(CREATE_EVENT_GAMIFICATION, { data: requestBody });      
        this.data_event = response;
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Event successfully created',
        });
        this.clearForm();
        this.onHide();
        this.getList();
      } catch (err) {
        popupErrorMessages(err.response.data);
      }
    },
    async doUpdateEvent() {
      this.$confirm(
        'You will update the Event. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
               const rewardsConfigWithIntegers = this.form.rewards_config.map(item => {
                if (this.form.type === "transaction_reward") {
                  return {
                    product: item.product,
                    coins: parseInt(item.coins) 
                  };
                } else if (this.form.type === "daily_login") {
                  return {
                    day: item.day, 
                    coins: parseInt(item.coins) 
                  };
                }
                return item; 
              });
              const requestBody = {
                name: this.form.name, 
                title: this.form.title, 
                description: this.form.description,
                companies: this.form.companies,
                companies_excluded: this.form.companies_excluded,
                type: this.form.type,
                user_type: this.form.user_type,
                start_date: this.form.start_date,
                end_date: this.form.end_date,
                max_claim_days: parseInt(this.form.max_claim_days),
                rewards_config: rewardsConfigWithIntegers 
              };
              const id = this.form._id;
              await this.$store.dispatch(UPDATE_EVENT_GAMIFICATION, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Event updated',
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async doUpdateStatus() {
      this.$confirm(
        'You will update the status. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                status: this.form.status, 
              };
              const id = this.form._id;
              await this.$store.dispatch(UPDATE_STATUS_EVENT_GAMIFICATION, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Status updated',
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async handlerBtnDelete(item) {
      this.selected_transaction = item
      this.$confirm(
        'You will delete the Event. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                status: this.selected_transaction.status, 
              };
              const id = this.selected_transaction._id;
              await this.$store.dispatch(DELETE_EVENT_GAMIFICATION, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Event deleted',
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    onHide() {
      this.isShownModalCreateEvent = false;
      this.isShownModalUpdateEvent = false;
      this.isShownModalUpdateStatus = false;
      this.clearForm();
    },
    clearForm() {
      this.form.name= '';
      this.form.title = '';
      this.form.description = '';
      this.form.companies = [];
      this.form.companies_excluded = [],
      this.form.status = '';
      this.form.user_type = '';
      this.form.type= '';
      this.form.start_date = '';
      this.form.end_date = '';
      this.form.max_claim_days = '';
      this.form.rewards_config = []
    },
  },
};
</script>